import { Menubar } from 'primereact/menubar'
import { MenuItem } from 'primereact/menuitem'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { useSignInCheck } from '../Hooks/useSignInCheck'
import { useUser } from '../Hooks/useUser'
import Logo from '../Imgs/Portal_WellBe_SBS.png'
import SignInOut from './SignInOutDialog'

export default function NavBar() {
  const { user } = useUser()
  const { loading, data: signInCheckResult } = useSignInCheck({ requiredClaims: { admin: true } })
  const [openSignOut, setOpenSignOut] = useState(false)
  const navigate = useNavigate()

  const onHomeSelect = () => {
    user?.getIdToken(true).then((token) => {
      console.log('token', token)
    })
    navigate('/')
  }
  const items: MenuItem[] = [
    {
      label: 'Member Search',
      icon: 'pi pi-fw pi-search',
      command: () => navigate('/search'),
      visible: signInCheckResult?.signedIn,
    },
    {
      label: 'Help',
      icon: 'pi pi-fw pi-fw pi-question-circle',
      command: () => navigate('/help'),
    },
    {
      label: 'Getting Started',
      icon: 'pi pi-fw pi-fw pi-info-circle',
      command: () => window.open('https://storage.googleapis.com/fs-template-files/Patient%20Care%20Portal%20-%20Getting%20Started.pdf'),
    },
    {
      label: 'Users',
      icon: 'pi pi-fw pi-fw pi-user',
      command: () => navigate('/users'),
      visible: signInCheckResult?.hasRequiredClaims || process.env.NODE_ENV === 'development',
    },
  ]

  const start = (
    <div className="mr-2">
      <img src={Logo} style={{ height: '65px', width: 'auto', cursor: 'pointer' }} onClick={onHomeSelect} />
    </div>
  )

  const end = (
    <div className="flex align-items-center gap-2" style={{ cursor: 'pointer' }} onClick={() => setOpenSignOut(true)}>
      {signInCheckResult?.user?.email ?? 'Sign In'}
    </div>
  )

  if (loading) return null
  return (
    <>
      <div className="card">
        <Menubar
          model={items}
          start={start}
          end={end}
          pt={{
            start: {
              style: { paddingLeft: '15vw' },
            },
            end: {
              style: { paddingRight: '15vw' },
            },
          }}
        />
      </div>
      <SignInOut open={openSignOut} setOpen={setOpenSignOut} />
    </>
  )
}

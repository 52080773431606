/**
 * Securely download a Blob object asynchronously
 * @param blob Blob object to download
 * @param fileName Name of the file to be downloaded
 */
export function downloadFile(blob: Blob, fileName: string): void {
  // Validate inputs
  if (!(blob instanceof Blob)) {
    console.error('Invalid blob: Must be a Blob object')
    return
  }

  if (!fileName || typeof fileName !== 'string') {
    console.error('Invalid fileName: Must be a non-empty string')
    return
  }

  // Sanitize filename to prevent potential XSS or path traversal
  const sanitizedFileName = fileName
    .replace(/[^\w.-]/g, '_') // Remove potentially dangerous characters
    .trim()
    .slice(0, 255) // Limit filename length

  try {
    // Ensure the browser supports necessary APIs
    if (!window.URL || !window.URL.createObjectURL) {
      throw new Error('Browser does not support file download')
    }

    // Create object URL with stricter security
    const objectUrl = window.URL.createObjectURL(blob)

    // Create download link with additional security attributes
    const downloadLink = document.createElement('a')
    downloadLink.href = objectUrl
    downloadLink.download = sanitizedFileName

    // Set additional security attributes
    downloadLink.rel = 'noopener noreferrer'
    downloadLink.setAttribute('aria-hidden', 'true')

    // Prevent link from being part of the document flow
    downloadLink.style.display = 'none'

    // Append to body, trigger download, and clean up
    document.body.appendChild(downloadLink)

    downloadLink.click()

    // Cleanup
    document.body.removeChild(downloadLink)
    window.URL.revokeObjectURL(objectUrl)
  } catch (error) {
    console.error('Error downloading file:', error)
  }
}

/**
 * Synchronously download a file from a URL
 * @param url URL of the file to download
 * @param fileName Optional custom filename
 */
export function downloadFileFromURL(url: string, fileName?: string): void {
  // Validate URL
  if (!url || typeof url !== 'string') {
    console.error('Invalid URL')
    return
  }

  try {
    // Create download link
    const downloadLink = document.createElement('a')
    downloadLink.href = url

    // Set filename if provided
    if (fileName) {
      downloadLink.download = fileName
    }

    // Set additional security attributes
    downloadLink.rel = 'noopener noreferrer'
    downloadLink.setAttribute('aria-hidden', 'true')

    // Prevent link from being part of the document flow
    downloadLink.style.display = 'none'

    downloadLink.click()
  } catch (error) {
    console.error('Error downloading file from URL:', error)
  }
}

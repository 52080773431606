import { FilterMatchMode } from 'primereact/api'
import { SortOrder } from 'primereact/datatable'
import { Dispatch, FC, ReactNode, SetStateAction, createContext, useEffect, useState } from 'react'
import { useSignInCheck } from './Hooks/useSignInCheck'
import { LazyState } from './Hooks/useWbapiInstance'

interface SearchStateContextType {
  state: LazyState
  setState: Dispatch<SetStateAction<LazyState>>
  loading: boolean
  payerIds: number[] | undefined
  setPayerIds: Dispatch<SetStateAction<number[] | undefined>>
  signedIn: boolean
}

const defaultState = {
  first: 0,
  rows: 10,
  page: 1,
  sortField: 'aaneelId',
  sortOrder: 1 as SortOrder,
  filters: {
    payerId: { value: undefined, matchMode: FilterMatchMode.IN },
    memberId: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    aaneelId: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    fullName: { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    dtOfBirth: { value: undefined, matchMode: FilterMatchMode.EQUALS },
    'plan.planName': { value: undefined, matchMode: FilterMatchMode.CONTAINS },
    payerPortalStatus: { value: undefined, matchMode: FilterMatchMode.EQUALS },
  },
}

export const SearchStateContext = createContext<SearchStateContextType>({
  state: defaultState,
  setState: () => {},
  loading: true,
  payerIds: undefined,
  setPayerIds: () => {},
  signedIn: false,
})

interface SearchStateContextProps {
  children: ReactNode
}

export const SearchStateProvider: FC<SearchStateContextProps> = ({ children }: SearchStateContextProps) => {
  const { data, loading } = useSignInCheck({ getPayerIds: true })
  const [payerIds, setPayerIds] = useState<number[]>()
  const [state, setState] = useState<LazyState>(defaultState)

  useEffect(() => {
    console.log('set payer ids state', data)
    if (data?.payerIds) {
      setPayerIds(data.payerIds)
    }
  }, [data, data?.user])

  return (
    <SearchStateContext.Provider value={{ state, setState, loading, payerIds, setPayerIds, signedIn: data?.signedIn ?? false }}>
      {children}
    </SearchStateContext.Provider>
  )
}

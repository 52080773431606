import { Card } from 'primereact/card'
import { ProgressSpinner } from 'primereact/progressspinner'
import React, { useContext, useEffect, useState } from 'react'
import MemberODataSearchTable from '../Components/MemberOdataSearchTable'
import { Member } from '../Hooks/useMemberOdata'
import { useNavigateParams } from '../Hooks/useNavigateParams'
import { SearchStateContext } from '../SearchStateContext'
import { Page } from './Page'

const Search: React.FC = () => {
  const [member, setMember] = useState<Member | undefined>()
  const { loading, signedIn, payerIds } = useContext(SearchStateContext)
  //nav that passes Id to url and picked up in useParams
  const navigate = useNavigateParams()

  useEffect(() => {
    if (member) {
      navigate('/docs', member.aaneelId, undefined, member)
    }
  }, [member])

  return (
    <Page title="Search">
      <div>
        <div>
          {loading && <ProgressSpinner />}
          {payerIds && payerIds.length == 0 && signedIn ? (
            <Card title="Unauthorized" style={{ minHeight: '10vh', maxWidth: '30vh', margin: 'auto' }}>
              <p>You are not authorized to view this page</p>
              <p>Your account must be assigned a payer to view members</p>
            </Card>
          ) : (
            !member && payerIds && <MemberODataSearchTable selectedMember={member} setSelection={setMember} />
          )}
        </div>
      </div>
    </Page>
  )
}

export default Search

import { getAnalytics, logEvent } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage } from 'firebase/storage'
import { PrimeReactProvider } from 'primereact/api'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './App.scss'
import NavRoutes from './Components/NavRoutes'
import { firebaseConfig } from './FirebaseConfig'
import { SearchStateProvider } from './SearchStateContext'

function App() {
  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)
  const storage = getStorage(app)
  const functions = getFunctions(app)

  if (process.env.NODE_ENV !== 'production') {
    // Set up emulators
    connectAuthEmulator(auth, 'http://127.0.0.1:9099')
    connectStorageEmulator(storage, '127.0.0.1', 9199)
    connectFunctionsEmulator(functions, '127.0.0.1', 5001)
  }

  const queryClient = new QueryClient()

  return (
    <div className="App">
      <Head />
      <QueryClientProvider client={queryClient}>
        <SearchStateProvider>
          <PrimeReactProvider>
            <MyPageViewLogger />
            <NavRoutes />
          </PrimeReactProvider>
        </SearchStateProvider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </div>
  )
}

export default App

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const MyPageViewLogger = (location: any) => {
  const analytics = getAnalytics()

  // By passing `location.pathname` to the second argument of `useEffect`,
  // we only log on first render and when the `pathname` changes
  useEffect(() => {
    logEvent(analytics, 'page_view', { page_location: location.href })
  }, [location.href])

  return null
}

// window.onbeforeunload = function () {
//   const auth = getAuth()
//   // This will run when the user closes the tab or refreshes the page
//   auth.signOut()
// }

const Head = () => {
  const isDevelopment = process.env.NODE_ENV === 'development'
  const connectSelf = [
    "'self'",
    'https://maps.googleapis.com',
    'https://firebase.googleapis.com',
    'https://firebaseinstallations.googleapis.com',
    'https://www.google-analytics.com',
    'https://storage.googleapis.com',
    'https://identitytoolkit.googleapis.com',
    'https://securetoken.googleapis.com',
    'https://us-central1-wb-payer-portal.cloudfunctions.net',
    'data:', //base64 images
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    process.env.REACT_APP_WBAPI!,
  ]
  const frameSelf = ["'self'", "'unsafe-inline'", 'https://storage.googleapis.com']
  if (isDevelopment) {
    console.log('IsDevelopment')
    //emulators
    connectSelf.push('http://127.0.0.1:9099')
    connectSelf.push('http://127.0.0.1:5001')
    frameSelf.push('http://127.0.0.1:9099')
  }
  const cspDirectives = {
    'default-src': ["'self'"],
    'script-src': ["'self'", 'https://www.googletagmanager.com'],
    'style-src': ["'self'", "'unsafe-inline'", 'https://cdn.jsdelivr.net', 'https://fonts.googleapis.com'],
    'script-src-elem': [
      "'self'",
      'https://www.googletagmanager.com',
      'https://maps.googleapis.com',
      'https://securetoken.googleapis.com',
      'https://apis.google.com',
    ],
    'font-src': ["'self'", 'https://cdn.jsdelivr.net'],
    'object-src': ["'self'", 'https://storage.googleapis.com', 'blob:'],
    'frame-src': frameSelf,
    'img-src': ["'self'", 'data:', 'https://maps.googleapis.com', 'https://maps.gstatic.com', 'https://www.googletagmanager.com'],
    'connect-src': connectSelf,
  }
  const generateCSP = (directives: { [key: string]: string[] }) => {
    return Object.entries(directives)
      .map(([key, values]) => `${key} ${values.join(' ')}`)
      .join('; ')
  }
  const cspContent = generateCSP(cspDirectives)
  return (
    <Helmet>
      <meta httpEquiv="Content-Security-Policy" content={cspContent} />
      <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
      <meta httpEquiv="Referrer-Policy" content="strict-origin-when-cross-origin" />
    </Helmet>
  )
}

import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import { useEffect, useState } from 'react'

//figure out matching this to what we need
export interface ClaimsCheckProps {
  requiredClaims?: {
    [key: string]: any
  }
  getPayerIds?: boolean
}

export interface ClaimCheckErrors {
  [key: string]: any[]
}

export type SignInCheckData =
  | {
      signedIn: false
      hasRequiredClaims: false
      errors: {}
      payerIds: undefined
      user: null
    }
  | {
      signedIn: true
      hasRequiredClaims: boolean
      errors: ClaimCheckErrors
      payerIds: number[] | undefined
      user: User
    }

export interface SignInCheckResult {
  data?: SignInCheckData
  loading: boolean
}

export const useSignInCheck = (props?: ClaimsCheckProps): SignInCheckResult => {
  const [loading, setLoading] = useState<boolean>(true)
  const [result, setResult] = useState<SignInCheckData>()
  const auth = getAuth()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult()
        const userClaims = idTokenResult.claims

        const errors: ClaimCheckErrors = {}
        let hasRequiredClaims = true
        let payerIds: number[] = []
        if (props) {
          if (props.requiredClaims) {
            for (const claim in props.requiredClaims) {
              if (userClaims[claim] !== props.requiredClaims[claim]) {
                hasRequiredClaims = false
                errors[claim] = [`Expected ${props.requiredClaims[claim]}, but got ${userClaims[claim]}`]
              }
            }
          }

          if (props.getPayerIds) {
            const claims = userClaims.claims as any[]
            if (claims) payerIds = claims.map((c: { claim: string }) => parseInt(c.claim))
          }
        }
        setResult({
          signedIn: true,
          hasRequiredClaims,
          errors,
          payerIds,
          user,
        })
      } else {
        setResult({
          signedIn: false,
          hasRequiredClaims: false,
          errors: {},
          payerIds: undefined,
          user: null,
        })
      }
      setLoading(false)
    })

    return () => unsubscribe()
  }, [])

  return { data: result, loading }
}

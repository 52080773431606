import { useState } from 'react'
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import Docs from '../Pages/Docs'
import HelpPage from '../Pages/Help'
import Search from '../Pages/Search'
import SignIn from '../Pages/SignIn'
import Users from '../Pages/Users'
import SignInOut from './SignInOutDialog'
import { useSignInCheck } from '../Hooks/useSignInCheck'

export default function NavRoutes() {
  return (
    <>
      <BrowserRouter>
        {/* <ErrorBoundary> */}
        <Routes>
          <Route index element={<SignIn />} />
          <Route path="/help" element={<HelpPage />} />
          <Route element={<PrivateRoute />}>
            <Route path="/search" element={<Search />} />
            <Route path="/users" element={<Users />} />
            <Route path="/docs/:id" element={<Docs />} />
          </Route>
          <Route
            path="*"
            element={
              <div className="d-flex justify-content-center" style={{ minHeight: '150vh', maxWidth: '100vh' }}>
                <h1>In Development: 404!</h1>
              </div>
            }
          />
        </Routes>
        {/* </ErrorBoundary> */}
      </BrowserRouter>
    </>
  )
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
const PrivateRoute = () => {
  const { loading, data: signInCheckResult } = useSignInCheck()
  const [showError, setShowError] = useState(true)
  const navigate = useNavigate()
  const handleClose = () => {
    setShowError(false)
    navigate('/')
  }

  const handleSignIn = () => {
    // const provider = new GoogleAuthProvider()
    // signInWithPopup(props.auth, provider)
  }

  if (loading) {
    return <div></div>
  }

  return signInCheckResult?.signedIn ? <Outlet /> : <SignInOut open={true} setOpen={() => navigate('/')} />
}

import { getAuth } from 'firebase/auth'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { ProgressSpinner } from 'primereact/progressspinner'
import { FC, ReactNode, useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router'
import NavBar from '../Components/NavBar'
import { useUser } from '../Hooks/useUser'

interface Props {
  children: ReactNode
  title: string
}
const timeout = 900_000 //15 min
const promptBeforeIdle = 60_000

export const Page: FC<Props> = (props) => {
  document.title = props.title

  const { user, loading } = useUser()

  const [remaining, setRemaining] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)
  const auth = getAuth()
  const navigate = useNavigate()

  const onIdle = () => {
    setOpen(false)
    auth.signOut()
    navigate('/')
  }

  const onActive = () => setOpen(false)

  const onPrompt = () => setOpen(true)

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)

  return (
    <>
      <NavBar />
      <>
        {timeTillPrompt == 0 && auth.currentUser != undefined && (
          <Dialog
            header="Are you still here?  "
            modal
            footer={<div>{<Button label="I'm still here" onClick={() => activate()} />}</div>}
            visible={open}
            onHide={() => activate()}
          >
            <p className="m-0">Logging out in {remaining} seconds</p>
          </Dialog>
        )}
      </>
      <div style={{ minHeight: '10vh', maxWidth: '80vw', margin: 'auto', paddingTop: '3vh' }}>
        {/* keep the page from loading until the api auth header is set unless home page */}
        {(!!user && !loading) || props.title == '' || props.title === 'Help' ? (
          props.children
        ) : (
          <div style={{ width: '5vw', margin: '0 auto' }}>
            <ProgressSpinner />
          </div>
        )}
        {/* some extra space to account for emulator mode message so we can see footer in development */}
        {process.env.NODE_ENV === 'development' && (
          <>
            <br />
            <br />
            <br />
          </>
        )}
      </div>
    </>
  )
}
